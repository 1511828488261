// Users Action Types
export const GET_USERS_LIST_START = "GET_USERS_LIST_START";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "GET_USERS_LIST_FAILURE";
export const SET_USER_SERVICES_LIST = "USER_SERVICES_LIST";
export const GET_USER_DATA_START = "GET_USER_DATA_START";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_ERROR";

export const ADD_NEW_USER_START = "ADD_NEW_USER_START";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAILURE = "ADD_NEW_USER_FAILURE";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const SEND_OTP_START = "SEND_OTP_START";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_ERROR";


export const VERIFY_OTP_START = "VERIFY_OTP_START";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const VERIFY_OTP_MESSAGE_HANDLER = "VERIFY_OTP_MESSAGE_HANDLER";

export const SEND_QUERY_START = "SEND_QUERY_START";
export const SEND_QUERY_SUCCESS = "SEND_QUERY_SUCCESS";
export const SEND_QUERY_FAILURE = "SEND_QUERY_ERROR";

// Auction Action Types
export const GET_AUCTION_LIST_START = "GET_AUCTION_LIST_START";
export const GET_AUCTION_LIST_SUCCESS = "GET_AUCTION_LIST_SUCCESS";
export const GET_AUCTION_LIST_FAILURE = "GET_AUCTION_LIST_FAILURE";
export const GET_AUCTION_DATA_START = "GET_AUCTION_DATA_START";
export const GET_AUCTION_DATA_SUCCESS = "GET_AUCTION_DATA_SUCCESS";
export const GET_AUCTION_DATA_FAILURE = "GET_AUCTION_DATA_ERROR";



export const GET_PAYMENT_DATA_START = "GET_PAYMENT_DATA_START";
export const GET_PAYMENT_DATA_SUCCESS = "GET_PAYMENT_DATA_SUCCESS";
export const GET_PAYMENT_DATA_FAILURE = "GET_PAYMENT_DATA_ERROR";

export const CREATE_AUCTION_START = "CREATE_AUCTION_START";
export const CREATE_AUCTION_SUCCESS = "CREATE_AUCTION_SUCCESS";
export const CREATE_AUCTION_FAILURE = "CREATE_AUCTION_ERROR";

/** Tender Action Types */
export const GET_TENDER_LIST_START = "GET_TENDER_LIST_START";
export const GET_TENDER_LIST_SUCCESS = "GET_TENDER_LIST_SUCCESS";
export const GET_TENDER_LIST_FAILURE = "GET_TENDER_LIST_ERROR";
export const TENDER_MESSAGE_HANDLER = "TENDER_MESSAGE_HANDLER";

/** Asset Action Types */
export const GET_CATEGORY_LIST_START = "GET_CATEGORY_LIST_START";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_FAILURE = "GET_CATEGORY_LIST_ERROR";
export const GET_GALLERY_CATEGORY_LIST_START = "GET_GALLERY_CATEGORY_LIST_START";
export const GET_GALLERY_CATEGORY_LIST_SUCCESS = "GET_GALLERY_CATEGORY_LIST_SUCCESS";
export const GET_GALLERY_CATEGORY_LIST_FAILURE = "GET_GALLERY_CATEGORY_LIST_ERROR"
export const GET_CHILD_CATEGORY_LIST_START = "GET_CHILD_CATEGORY_LIST_START";
export const GET_CHILD_CATEGORY_LIST_SUCCESS = "GET_CHILD_CATEGORY_LIST_SUCCESS";
export const GET_CHILD_CATEGORY_LIST_FAILURE = "GET_CHILD_CATEGORY_LIST_ERROR";
export const GET_GALLERY_LIST_START = "GET_GALLERY_LIST_START";
export const GET_GALLERY_LIST_SUCCESS = "GET_GALLERY_LIST_SUCCESS";
export const GET_GALLERY_LIST_FAILURE = "GET_GALLERY_LIST_ERROR";
export const CATEGORY_MESSAGE_HANDLER = "CATEGORY_MESSAGE_HANDLER"; 

/** CMS Action Types */
export const GET_NEWS_LIST_START = "GET_NEWS_LIST_START";
export const GET_NEWS_LIST_SUCCESS = "GET_NEWS_LIST_SUCCESS";
export const GET_NEWS_LIST_FAILURE = "GET_NEWS_LIST_ERROR";
export const GET_MORE_NEWS_LIST_START = "GET_MORE_NEWS_LIST_START";
export const GET_MORE_NEWS_LIST_SUCCESS = "GET_MORE_NEWS_LIST_SUCCESS";
export const GET_MORE_NEWS_LIST_FAILURE = "GET_MORE_NEWS_LIST_ERROR";
export const GET_NEWS_DETAIL_START = "GET_NEWS_DETAIL_START";
export const GET_NEWS_DETAIL_SUCCESS = "GET_NEWS_DETAIL_SUCCESS";
export const GET_NEWS_DETAIL_FAILURE = "GET_NEWS_DETAIL_ERROR";
export const GET_PROJECT_LIST_START = "GET_PROJECT_LIST_START";
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS";
export const GET_PROJECT_LIST_FAILURE = "GET_PROJECT_LIST_ERROR";
export const GET_PROJECT_DETAIL_START = "GET_PROJECT_DETAIL_START";
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS";
export const GET_PROJECT_DETAIL_FAILURE = "GET_PROJECT_DETAIL_ERROR";
export const GET_HOME_DATA_START = "GET_HOME_DATA_START";
export const GET_HOME_DATA_SUCCESS = "GET_HOME_DATA_SUCCESS";
export const GET_HOME_DATA_FAILURE = "GET_HOME_DATA_ERROR";
export const CMS_MESSAGE_HANDLER = "CMS_MESSAGE_HANDLER"; 


export const MESSAGE_HANDLER = "MESSAGE_HANDLER"; 

export const CREATE_ORDER_MESSAGE_HANDLER = "CREATE_ORDER_MESSAGE_HANDLER"; 

export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGIN_ERROR_HANDLER_SUCCESS = "LOGIN_ERROR_HANDLER_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";


export const DASHBOARD_START = "DASHBOARD_START";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";
export const DASHBOARD_ERROR_HANDLER_SUCCESS = "DASHBOARD_ERROR_HANDLER_SUCCESS";

export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const HELP_HANDLER = "HELP_HANDLER";

// Restaurant Action Types

export const GET_RESTAURANT_DATA_START = "GET_RESTAURANT_DATA_START";
export const GET_RESTAURANT_DATA_SUCCESS = "GET_RESTAURANT_DATA_SUCCESS";
export const GET_RESTAURANT_DATA_FAILURE = "GET_RESTAURANT_DATA_FAILURE";

///////////////////////Banner action///
export const GET_BANNERS_START = "GET_BANNERS_START";
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS";
export const GET_BANNERS_FAILURE = "GET_BANNERS_FAILURE";


export const GET_RESTAURANT_CATEGORIES_START = "GET_RESTAURANT_CATEGORIES_START";
export const GET_RESTAURANT_CATEGORIES_SUCCESS = "GET_RESTAURANT_CATEGORIES_SUCCESS";
export const GET_RESTAURANT_CATEGORIES_FAILURE = "GET_RESTAURANT_CATEGORIES_FAILURE";

export const GET_PRODUCT_LIST_START = "GET_PRODUCT_LIST_START";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAILURE = "GET_PRODUCT_LIST_FAILURE";

export const GET_CART_ITEMS_LIST_START = "GET_CART_ITEMS_LIST_START";
export const GET_CART_ITEMS_LIST_SUCCESS = "GET_CART_ITEMS_LIST_SUCCESS";
export const GET_CART_ITEMS_LIST_FAILURE = "GET_CART_ITEMS_LIST_FAILURE";

export const FETCH_ALL_LANGUAGES_REQUEST = 'FETCH_ALL_LANGUAGES_REQUEST';
export const FETCH_ALL_LANGUAGES_SUCCESS = 'FETCH_ALL_LANGUAGES_SUCCESS';
export const FETCH_ALL_LANGUAGES_FAILURE = 'FETCH_ALL_LANGUAGES_FAILURE';


export const GET_ALL_KEYWORD_START = 'GET_ALL_KEYWORD_START';
export const GET_ALL_KEYWORD_SUCCESS = 'GET_ALL_KEYWORD_SUCCESS';
export const GET_ALL_KEYWORD_FAILURE = 'GET_ALL_KEYWORD_FAILURE';




export const GET_ALL_DETAIL_LANG_START = 'GET_ALL_DETAIL_LANG_START';
export const GET_ALL_DETAIL_LANG_SUCCESS = 'GET_ALL_DETAIL_LANG_SUCCESS';
export const GET_ALL_DETAIL_LANG_FAILURE = 'GET_ALL_DETAIL_LANG_FAILURE';

export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';

export const UPDATE_ORDER_ITEM_START = 'UPDATE_ORDER_ITEM_START';
export const UPDATE_ORDER_ITEM_SUCCESS = 'UPDATE_ORDER_ITEM_SUCCESS';
export const UPDATE_ORDER_ITEM_FAILURE = 'UPDATE_ORDER_ITEM_FAILURE';

export const GET_ORDER_START = 'GET_ORDER_START';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';

export const UPDATE_ORDER_START = 'UPDATE_ORDER_START';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const GET_ORDER_DATA_START = 'GET_ORDER_DATA_START';
export const GET_ORDER_DATA_SUCCESS = 'GET_ORDER_DATA_SUCCESS';
export const GET_ORDER_DATA_FAILURE = 'GET_ORDER_DATA_FAILURE';


export const CREATE_REVOULT_ORDER_FOR_PAYMENT_START = 'CREATE_REVOULT_ORDER_FOR_PAYMENT_START';
export const CREATE_REVOULT_ORDER_FOR_PAYMENT_SUCCESS = 'CREATE_REVOULT_ORDER_FOR_PAYMENT_SUCCESS';
export const CREATE_REVOULT_ORDER_FOR_PAYMENT_FAILURE = 'CREATE_REVOULT_ORDER_FOR_PAYMENT_FAILURE';

export const CREATE_ETISILAT_ORDER_FOR_PAYMENT_START = 'CREATE_ETISILAT_ORDER_FOR_PAYMENT_START';
export const CREATE_ETISILAT_ORDER_FOR_PAYMENT_SUCCESS = 'CREATE_ETISILAT_ORDER_FOR_PAYMENT_SUCCESS';
export const CREATE_ETISILAT_ORDER_FOR_PAYMENT_FAILURE = 'CREATE_ETISILAT_ORDER_FOR_PAYMENT_FAILURE';

export const GET_POPULAR_PRODUCTS_START = 'GET_POPULAR_PRODUCTS_START';
export const GET_POPULAR_PRODUCTS_SUCCESS = 'GET_POPULAR_PRODUCTS_SUCCESS';
export const GET_POPULAR_PRODUCTS_FAILURE = 'GET_POPULAR_PRODUCTS_FAILURE';

export const SEND_EMAIL_START = 'SEND_EMAIL_START';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

// Branch Action Types

export const GET_BRANCH_LIST_START = "GET_BRANCH_LIST_START";
export const GET_BRANCH_LIST_SUCCESS = "GET_BRANCH_LIST_SUCCESS";
export const GET_BRANCH_LIST_FAILURE = "GET_BRANCH_LIST_FAILURE";

// Retrieve Payment List

export const GET_PAYMENT_GATEWAYS_START = "GET_PAYMENT_GATEWAYS_START";
export const GET_PAYMENT_GATEWAYS_SUCCESS = "GET_PAYMENT_GATEWAYS_SUCCESS";
export const GET_PAYMENT_GATEWAYS_FAILURE = "GET_PAYMENT_GATEWAYS_FAILURE";

export const GET_ORDER_PAYMENT_LIST_START = "GET_ORDER_PAYMENT_LIST_START";
export const GET_ORDER_PAYMENT_LIST_SUCCESS = "GET_ORDER_PAYMENT_LIST_SUCCESS";
export const GET_ORDER_PAYMENT_LIST_FAILURE = "GET_ORDER_PAYMENT_LIST_FAILURE";

export const SET_PAY_STATUS = 'SET_PAY_STATUS';

// Update Payment Details at our end

export const UPDATE_ORDER_PAYMENT_START = "UPDATE_ORDER_PAYMENT_START";
export const UPDATE_ORDER_PAYMENT_SUCCESS = "UPDATE_ORDER_PAYMENT_SUCCESS";
export const UPDATE_ORDER_PAYMENT_FAILURE = "UPDATE_ORDER_PAYMENT_FAILURE";